/*.body {
    background-color: #F5F5F5;
}*/

.home-intro {
    /*background-image: url("http://localhost:3000/img/img.png");
    background-repeat: repeat;
    backdrop-filter: brightness(20%);*/
    /*background-color: rgba(112.520718,44.062154,249.437846,0.065)*/
    background: linear-gradient(0deg, #702CF910 0%, #702CF91F 100%);
}

.home-bar {
    background-color: #d7fffc;
    border: 1px solid #0bc1b6;
}

.home-try {
    /*background: linear-gradient(#17082e 0%, #1a0933 7%, #1a0933 80%, #0c1f4c 100%);*/
    /*background: linear-gradient(0deg, rgba(102,128,255,0.3) 0%, rgba(113,44,249,0.01) 100%);*/
    /*background-color: #F5F5F5;*/
    background: linear-gradient(0deg, #ebebeb 0%, #FFFFFF 100%);
}

.home-teacher {
    background: linear-gradient(#17082e 0%, #1a0933 7%, #1a0933 80%, #0c1f4c 100%);
}

.home-dark-block {
    background-color: rgb(255, 255, 255, 50%);
}

.form-control:focus, .form-select:focus, .btn:focus {
    box-shadow: none !important;
}

.b-none, .b-none:hover, .b-none:focus {
    border: 0 none !important;
    outline: none 0 !important;
}

.login-container {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: linear-gradient(to bottom right, #eb6864, #f5e625);
}

.login-box {
    background: white;
    border-radius: 9px;
    min-width: 100%;
    height: 100%;
}

.login-content {
    box-sizing: border-box;
    height: 100%;
    padding: 0.75rem 1.5rem;
    display: flex;
    justify-content: center;
    flex-flow: column;
}

.login-body {
    flex: 1 1 auto;
    text-align: center;
}

.login-footer {
    flex: 0 1 auto;
    text-align: center;
}

.login-content > * > h1 {
    font-size: calc(1.625rem + 4.5vw);
    margin-bottom: 4.75rem;
    line-height: 1.1;
}

.lmb {
    margin-bottom: 2rem;
}

.mmb {
    margin-bottom: 1.5rem;
}

.smb {
    margin-bottom: 1rem;
}

@media (min-width: 1200px) {
    .login-content > * > h1 {
        font-size: 5rem;
    }
}

@media only screen and (min-width: 992px) {
    .login-box {
        min-width: 30%;
        min-height: 37%;
        height: fit-content;
    }
}

@media only screen and (min-width: 3000px) {
    .login-box {
        min-width: 17%;
    }
}

.pointer {
    cursor: pointer;
}

.cursor-text {
    cursor: text;
}

.text {
    cursor: text;
}

.w-fit-content {
    width: fit-content;
}

.resize-none {
    resize: none;
}

.pencil::before {
    content: "\f4cb";
}

.pencil:hover::before {
    content: "\f4c9";
}

div > .input {
    border: 1px solid #ced4da;
    border-radius: 0.375rem;
}

.nav-tabs-show {
    isolation: isolate !important;
    border-color: #eee #eee #dee2e6 !important;
}

.badge-gap {
    row-gap: 0.75rem !important;
    column-gap: 1.1rem !important;
}

.w-max {
    min-width: auto;
}

.close-sm {
    font-size: 0.7rem !important;
}

.bg-light-gray {
    background-color: rgb(225, 225, 225);
}

.rectangle-lg {
    width: 115vw;
    height: 5vh;
    background-color: #f57a00;
    transform: rotate(-20deg);
    position: fixed;
    top: 47vh;
    left: -5vw;
}

.rectangle-sm {
    width: 30vw;
    height: 5vh;
    background-color: #eb6864;
    transform: rotate(-20deg);
    position: fixed;
    top: 77vh;
    left: 5vw;
}

.triangle {
    background-color: rgb(247, 250, 252);
    position: fixed;
    top: -30vh;
    left: -30vw;
    width: 150vw;
    height: 80vh;
    transform: rotate(-20deg);
}

.scroll-y {
    overflow-x: hidden;
    overflow-y: scroll;
}

/* width */
.home-try-scroll::-webkit-scrollbar {
    width: 6px;
}

/* Track */
.home-try-scroll::-webkit-scrollbar-track {
    border-radius: 10px;
    border: green 1px solid;
}

/* Handle */
.home-try-scroll::-webkit-scrollbar-thumb {
    background: darkgreen;
    border-radius: 10px;
}

/* Handle on hover */
.home-try-scroll::-webkit-scrollbar-thumb:hover {
    background: green;
}

.font-fantasy {
    font-family: fantasy;
}

.hover-grow {
    transition: all .2s ease-in-out;
}

.hover-grow:hover {
    transform: scale(1.1);
}

.font-xxl {
    font-size: 12rem;
}
